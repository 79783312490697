import React from "react";
import { ProductSubCategoryHeroProps } from "./Hero";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@ryerson/frontend.button";
import { Icon } from "@ryerson/frontend.assets";
import { Link } from "@ryerson/frontend.navigation";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { ShopByShapeItem, ContentfulGeneralReference } from "../Types/Types";

const BreadcrumbContainer = styled.div`
	margin-bottom: 11px;
`;

const ProductsDiv = styled.div`
	margin-top: 15px;
	margin-bottom: 37px;
`;

const ProductDivider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 10px;
	margin-bottom: 15px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.header};
		`;
	}}
`;

const ProductContainer = styled.div`
	width: 100%;
	position: relative;
	margin-bottom: 20px;
`;

const ProductBorderBox = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
	opacity: 0.1;
	${(props: any) => {
		const { theme } = props;
		return css`
			border: 1px solid ${theme.colors.primary.header};
		`;
	}}
`;

const ProductContent = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px;
	z-index: 1;
	position: relative;
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 70px;
	margin-bottom: 10px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.header};
		`;
	}}
`;

const ProductsSubCategoryHeroMobile: React.FC<ProductSubCategoryHeroProps> = ({
	staticContent,
	dynamicContent,
}) => {
	const { theme } = useTheme();

	const productLabel = css`
		margin-bottom: 4px;
	`;

	const productLine = css`
		margin-top: 0px;
		margin-bottom: 18px;
	`;

	return (
		<>
			<ContentSection type="tertiary" vPadding={"30px"}>
				<BreadcrumbContainer>
					<Breadcrumb type="tertiary" size="xs" />
				</BreadcrumbContainer>
				<Typography
					type="tertiary"
					variant="h1"
					css={css`
						margin-bottom: 34px;
					`}
				>
					{dynamicContent.title}
				</Typography>
				<Link to={staticContent.buttonUrl} gatsby={false}>
					<Button
						size="xl"
						type="primary"
						label={staticContent.buttonLabel}
						onClick={() => {}}
					></Button>
				</Link>

				<Typography
					type="tertiary"
					variant="p"
					size="md"
					css={css`
						margin-bottom: 20px;
						:first-of-type {
							margin-top: 30px;
						}
					`}
				>
					{dynamicContent.blurb}
				</Typography>
				{dynamicContent.selectionGuide && dynamicContent.selectionGuide.file && (
					<>
						<Link to={dynamicContent.selectionGuide.file.url} gatsby={false}>
							<Typography
								variant="p"
								type="tertiary"
								size="lg"
								color={theme.colors.primary.link}
								css={css`
									margin-bottom: 18px;
								`}
							>
								<Icon
									icon="file-pdf"
									color={theme.colors.primary.link}
									css={css`
										vertical-align: middle;
										margin-right: 11px;
									`}
								/>
								{staticContent.selectionGuide + ": " + dynamicContent.title}
							</Typography>
						</Link>
					</>
				)}
				<Typography
					variant="h3"
					type="tertiary"
					css={css`
						margin-top: 50px;
						margin-bottom: 31px;
					`}
				>
					{staticContent.generalReference}
				</Typography>
				{dynamicContent.references.map((ref: ContentfulGeneralReference, index: number) => {
					return (
						<Link key={index} to={ref.pdf.file.url} gatsby={false}>
							<Typography
								variant="p"
								type="tertiary"
								size="lg"
								color={theme.colors.primary.link}
								css={css`
									margin-bottom: 18px;
								`}
							>
								<Icon
									icon="file-pdf"
									color={theme.colors.primary.link}
									css={css`
										vertical-align: middle;
										margin-right: 11px;
									`}
								/>
								{ref.pdf.title}
							</Typography>
						</Link>
					);
				})}
				{dynamicContent.properties && dynamicContent.properties.length > 0 && (
					<>
						<Typography
							variant="h3"
							type="tertiary"
							css={css`
								margin-top: 50px;
								margin-bottom: 31px;
							`}
						>
							{staticContent.materialProperties}
						</Typography>
						{dynamicContent.properties.map(
							(ref: ContentfulGeneralReference, index: number) => {
								return (
									<Link key={index} to={ref.pdf.file.url} gatsby={false}>
										<Typography
											variant="p"
											type="tertiary"
											size="lg"
											color={theme.colors.primary.link}
											css={css`
												margin-bottom: 18px;
											`}
										>
											<Icon
												icon="file-pdf"
												color={theme.colors.primary.link}
												css={css`
													vertical-align: middle;
													margin-right: 11px;
												`}
											/>
											{ref.pdf.title}
										</Typography>
									</Link>
								);
							}
						)}
					</>
				)}
				{dynamicContent.shopNowLink && (
					<>
						<Link to={dynamicContent.shopNowLink} gatsby={false}>
							<Button
								type="secondary"
								size="lg"
								onClick={() => {}}
								label={staticContent.shopNow}
							/>
						</Link>
					</>
				)}
			</ContentSection>
			{dynamicContent.shopByShape && dynamicContent.shopByShape.length > 0 ? (
				<>
					<ContentSection type="primary" vPadding={"0px"}>
						<Typography
							variant="h3"
							type="primary"
							css={css`
								border-bottom: 1px solid ${theme.colors.primary.primaryBrand};
								padding-bottom: 26px;
								margin-top: 40px;
								margin-bottom: 40px;
							`}
						>
							{staticContent.shopByShape}
						</Typography>
						<ProductsDiv>
							{dynamicContent.shopByShape.map(function (
								product: ShopByShapeItem,
								index: number
							) {
								return (
									<ProductContainer theme={theme}>
										<ProductBorderBox theme={theme} />
										<ProductContent theme={theme}>
											<Link
												key={index}
												to={product.link ?? "#"}
												gatsby={false}
											>
												<Flex>
													<FlexItem>
														<img
															css={css`
																width: 60px;
																height: 60px;
															`}
															src={product.rendering?.file.url}
														/>
													</FlexItem>
													<FlexItem
														alignSelf="center"
														css={css`
															padding-left: 20px;
														`}
													>
														<Typography
															variant="p"
															color={
																theme.colors.primary.primaryBrand
															}
															size="lg"
															weight="bold"
														>
															{product.shape}
														</Typography>
													</FlexItem>
												</Flex>
											</Link>
											<ProductDivider theme={theme} />
											<Typography
												type="primary"
												variant="p"
												size="md"
												color={theme.colors.primary.label}
												weight="bold"
												css={productLabel}
											>
												{staticContent.shopByShapeHeader.grade}
											</Typography>
											<Typography
												type="primary"
												variant="p"
												size="md"
												color={theme.colors.primary.text}
												css={productLine}
											>
												{product.grade}
											</Typography>
											<Typography
												type="primary"
												variant="p"
												size="md"
												color={theme.colors.primary.label}
												weight="bold"
												css={productLabel}
											>
												{staticContent.shopByShapeHeader.type}
											</Typography>
											<Typography
												type="primary"
												variant="p"
												size="md"
												color={theme.colors.primary.text}
												css={productLine}
											>
												{product.type}
											</Typography>
											<Typography
												type="primary"
												variant="p"
												size="md"
												color={theme.colors.primary.label}
												weight="bold"
												css={productLabel}
											>
												{staticContent.shopByShapeHeader.size}
											</Typography>
											{product.size && Array.isArray(product.size) ? (
												product.size.map(function (
													text: any,
													index: number
												) {
													return (
														<Typography
															type="primary"
															variant="p"
															size="md"
															color={theme.colors.primary.text}
															css={css`
																margin-bottom: 0px;
																margin-top: 0px;
																:first-of-type {
																	margin-top: 16px;
																}
															`}
															key={index}
														>
															{text}
														</Typography>
													);
												})
											) : (
												<Typography
													type="primary"
													variant="p"
													size="md"
													color={theme.colors.primary.text}
													css={css`
														margin-bottom: 0px;
														margin-top: 0px;
														:first-of-type {
															margin-top: 16px;
														}
													`}
													key={index}
												>
													{product.size}
												</Typography>
											)}
										</ProductContent>
									</ProductContainer>
								);
							})}
						</ProductsDiv>
						<Divider theme={theme} />
					</ContentSection>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default ProductsSubCategoryHeroMobile;
